/* eslint-disable prettier/prettier */
import React from "react";
import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// images
import image1 from "assets/img/conference.jpg";
// core components
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import styles from "assets/jss/material-kit-react/views/componentsSections/aboutUsStyle.js";

const useStyles = makeStyles(styles);

export default function CompanyLoans() {
    const classes = useStyles();
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        adaptiveHeight: true,
    };
    return (
        <div id="vision" className={classes.container}>
            <div className={classes.section}>
                <div>
                    <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={8}>
                            <h2 className={classes.title}>Företagslån</h2>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6} className={classes.marginAuto}>
                            <Card carousel>
                                <Carousel {...settings}>
                                    <div>
                                        <img src={image1} alt="Constructions site" className="slick-image" />
                                    </div>
                                </Carousel>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <h5 className={classes.description}>
                                Kapital för expansion eller förvärv kan vara krångligt. Men inte hos oss.
                                Vi förstår att möjligheten till expansion ofta hänger ihop med en större investering.
                                Kanske är det ett företagsförvärv, en ny köksmaskin eller att stärka ert kassaflöde?
                                Vi hjälper dig att nå ditt företags maximala potential.
                            </h5>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        </div>
    );
}
