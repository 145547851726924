/* eslint-disable prettier/prettier */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
// import Business from "@material-ui/icons/Business";
// import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// import Nature from "@material-ui/icons/Nature";
// images
import Carousel from "react-slick";
import image1 from "assets/img/construction.jpg";
import Card from "components/Card/Card.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import InfoArea from "components/InfoArea/InfoArea.js";
import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";
import { List, ListItem } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function Finans() {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    adaptiveHeight: true,
  };

  return (
    <div className={classes.bgColor}>
      <div id="finance" className={classes.container}>
        <div className={classes.section}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={8}>
              <h2 className={classes.title}>Fastighetsfinansiering</h2>
            </GridItem>
            {/* Text */}
            <GridItem xs={12} sm={12} md={6}>
              <h5 className={classes.description}>
                Projektfinans arbetar med flera olika typer av fastighetslån för att vi ska kunna skräddarsy den bästa lösningen för våra kunder.
                Dessa är förvärvslån, byggnadskreditiv, seniorlån och juniorlån.
                Det innebär att vi kan delta under hela projektet, från markförvärvet till byggnadskreditiv.
              </h5>
              <List className={classes.list}>
                <ListItem>
                  &#8226; Markförvärv
                </ListItem>
                <ListItem> 
                  &#8226; Byggnadskreditiv
                  </ListItem>
                <ListItem>
                  &#8226; Fastighetslån
                  </ListItem>
              </List>
              {/* <InfoArea
                title="Fastighetslån"
                bullets={["Markförvärv", "Byggnadskreditiv", "Fastighetslån"]}
                icon={Nature}
                iconColor="danger"
                vertical
              /> */}
            </GridItem>
            {/* Image */}
            <GridItem xs={12} sm={12} md={6} className={classes.marginAuto}>
              <Card carousel>
                <Carousel {...settings}>
                  <div>
                    <img src={image1} alt="First slide" className="slick-image" />
                  </div>
                </Carousel>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
