/* eslint-disable prettier/prettier */
import { title, container } from "assets/jss/material-kit-react.js";

const aboutUsStyle = (theme) => ({
    section: {
        padding: "70px 0",
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
            padding: "10px 0",
            marginBottom: 30,
        },
    },
    title: {
        ...title,
        marginBottom: "2rem",
        marginTop: "30px",
        minHeight: "32px",
        textDecoration: "none",
        [theme.breakpoints.down("sm")]: {
            fontSize: 28,
            wordWrap: "break-word",
        },
    },
    container,
    marginAuto: {
        marginLeft: "auto !important",
        marginRight: "auto !important",
        "& > div": {
            margin: 0,
        },
    },
    description: {
        textAlign: "left",
    }
});

export default aboutUsStyle;
