/* eslint-disable prettier/prettier */
import React from "react";
// nodejs library that concatenates classes
// import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
// import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";
import Email from "@material-ui/icons/Email";
import Phone from "@material-ui/icons/Phone";
// import johnDoe from "assets/img/faces/profilePic.png";

const useStyles = makeStyles(styles);

export default function Team() {
    const classes = useStyles();
    // const imageClasses = classNames(
    //     classes.imgRaised,
    //     classes.imgRoundedCircle,
    //     classes.imgFluid
    // );
    return (
        <div id="team" className={classes.container}>
            <div className={classes.section}>
                <h2 className={classes.title}>Teamet</h2>
                <div>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                            <Card plain>
                                {/* <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                                    <img src={johnDoe} alt="..." className={imageClasses} />
                                </GridItem> */}
                                <h4 className={classes.cardTitle}>
                                    Magnus Minari
                                    <br />
                                    {/* <small className={classes.smallTitle}>Säljare</small> */}
                                </h4>
                                {/* <CardBody>
                                <p className={classes.description}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    In nec orci consectetur, finibus sapien at, euismod sapien.
                                    In sagittis enim sed consectetur semper.
                                </p>
                            </CardBody> */}
                                <CardFooter xs={12} sm={12} md={4} className={classes.justifyCenter}>
                                    <Button
                                        color="transparent"
                                        className={classes.margin5}
                                        href="tel:+46705426748"
                                    >
                                        <Phone className={classes.icons} />070 542 67 48

                                    </Button>
                                    <Button
                                        color="transparent"
                                        className={classes.margin5}
                                        href="mailto:magnus@projektfinans.se"
                                    >
                                        <Email className={classes.icons} />magnus@projektfinans.se

                                    </Button>
                                </CardFooter>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <Card plain>
                                {/* <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                                    <img src={johnDoe} alt="..." className={imageClasses} />
                                </GridItem> */}
                                <h4 className={classes.cardTitle}>
                                    Rasmus Rajalakso
                                    <br />
                                    {/* <small className={classes.smallTitle}>Säljare</small> */}
                                </h4>
                                {/* <CardBody>
                                <p className={classes.description}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    In nec orci consectetur, finibus sapien at, euismod sapien.
                                    In sagittis enim sed consectetur semper.
                                </p>
                            </CardBody> */}
                                <CardFooter xs={12} sm={12} md={4} className={classes.justifyCenter}>
                                    <Button
                                        color="transparent"
                                        className={classes.margin5}
                                        href="tel:+46735144331"
                                    >
                                        <Phone className={classes.icons} />073 514 43 31

                                    </Button>
                                    <Button
                                        color="transparent"
                                        className={classes.margin5}
                                        href="mailto:rasmus@projektfinans.se"
                                    >
                                        <Email className={classes.icons} />rasmus@projektfinans.se

                                    </Button>
                                </CardFooter>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <Card plain>
                                {/* <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                                    <img src={johnDoe} alt="..." className={imageClasses} />
                                </GridItem> */}
                                <h4 className={classes.cardTitle}>
                                    Marcus Minari
                                    <br />
                                    {/* <small className={classes.smallTitle}>Säljare</small> */}
                                </h4>
                                {/* <CardBody>
                                <p className={classes.description}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. In nec orci consectetur,
                                    finibus sapien at, euismod sapien.
                                    In sagittis enim sed consectetur semper.
                                </p>
                            </CardBody> */}
                                <CardFooter xs={12} sm={12} md={4} className={classes.justifyCenter}>
                                    <Button
                                        color="transparent"
                                        className={classes.margin5}
                                        href="tel:+46735230003"
                                    >
                                        <Phone className={classes.icons} />073 523 00 03

                                    </Button>
                                    <Button
                                        color="transparent"
                                        className={classes.margin5}
                                        href="mailto:marcus@projektfinans.se"
                                    >
                                        <Email className={classes.icons} />marcus@projektfinans.se

                                    </Button>
                                </CardFooter>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        </div>
    );
}
