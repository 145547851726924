/* eslint-disable prettier/prettier */
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
// import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Button from "components/CustomButtons/Button.js";
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";
import Hidden from "@material-ui/core/Hidden";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
import Team from "./Sections/Team.js";
import Finans from "./Sections/Finans.js";
import CompanyLoans from "./Sections/CompanyLoans.js";
// import SectionBasics from "./Sections/SectionBasics.js";
// import SectionNavbars from "./Sections/SectionNavbars.js";
// import SectionTabs from "./Sections/SectionTabs.js";
// import SectionPills from "./Sections/SectionPills.js";
// import SectionNotifications from "./Sections/SectionNotifications.js";
// import SectionTypography from "./Sections/SectionTypography.js";
// import SectionJavascript from "./Sections/SectionJavascript.js";
// import SectionCarousel from "./Sections/SectionCarousel.js";
// import SectionCompletedExamples from "./Sections/SectionCompletedExamples.js";
// import SectionLogin from "./Sections/SectionLogin.js";
// import SectionExamples from "./Sections/SectionExamples.js";
// import SectionDownload from "./Sections/SectionDownload.js";

import styles from "assets/jss/material-kit-react/views/components.js";
import Vision from "./Sections/Vision.js";
import Contact from "./Sections/Contact.js";

function scrollToTargetAdjusted(target) {
  var element = document.getElementById(target);
  var headerOffset = 135;
  const bodyRect = document.body.getBoundingClientRect().top;
  const elementRect = element.getBoundingClientRect().top;
  const elementPosition = elementRect - bodyRect;
  const offsetPosition = elementPosition - headerOffset;

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });
}

const useStyles = makeStyles(styles);

export default function Components(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        brand="Projekt Finans"
        imgSrc={require("assets/img/pf_logo.png").default}
        // imgSrc={require("assets/img/projektfinans_logo.png").default}
        rightLinks={<HeaderLinks />}
        fixed
        color="white" // Transparent if image is darker
        // changeColorOnScroll={{
        //   height: 400,
        //   color: "white", 
        // }}
        {...rest}
      />
      <Parallax image={require("assets/img/stockholm-pf.jpg").default}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={8}>
              <div className={classes.brand}>
                {/* <h1 className={classes.title}>
                  Er företagsresa börjar med oss.
                </h1> */}
                <h4 className={classes.subTitle}>
                Vi har som ambition att få Sverige att växa genom att bistå med kapitalanskaffning för företagarens nästa steg. 
                Vi hjälper till med allt inom fastighetsfinansiering och företagsfinansiering. 
                Vi tror på ett starkt Sverige i ständig tillväxt. Så står du inför ett nytt projekt och söker finansiering - 
                Välkommen att kontakta oss. 

                </h4>
                <Hidden smDown>
                <br />
                </Hidden>
                <Button
                  color="info"
                  size="lg"
                  onClick={() => scrollToTargetAdjusted('contact')}
                >
                  Kontakta oss
                </Button>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>

      <div className={classNames(classes.main, classes.mainRaised)}>
        <Vision />
        <Finans />
        <CompanyLoans />
        <Team />
        <Contact />
        {/* <SectionBasics /> */}
        {/* <SectionNavbars /> */}
        {/* <SectionTabs />
        <SectionPills />
        <SectionNotifications /> */}
        {/* <SectionTypography />
        <SectionJavascript />
        <SectionCarousel />
        <SectionCompletedExamples />
        <SectionLogin />
        <GridItem md={12} className={classes.textCenter}>
          <Link to={"/login-page"} className={classes.link}>
            <Button color="primary" size="lg" simple>
              View Login Page
            </Button>
          </Link>
        </GridItem>
        <SectionExamples />
        <SectionDownload /> */}
      </div>
      <Footer />
    </div>
  );
}
