/* eslint-disable prettier/prettier */
import React from "react";
import { useState } from 'react';
import { send } from 'emailjs-com';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons
import TextFields from "@material-ui/icons/TextFields";
import Payment from "@material-ui/icons/Payment";
import People from "@material-ui/icons/People";
import Person from "@material-ui/icons/Person";
import Dialpad from "@material-ui/icons/Dialpad";
import Phone from "@material-ui/icons/Phone";
import Email from "@material-ui/icons/Email";
// Snack bar
import 'js-snackbar/snackbar.css';
import { show } from 'js-snackbar';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/loginStyle.js";

const useStyles = makeStyles(styles);

// HEY YOO 
// To send email use this tutorial: https://dev.to/daliboru/how-to-send-emails-from-a-form-in-react-emailjs-27d1

export default function Contact() {
  const classes = useStyles();

  const initialState = {
    from_name: '',
    company_name: '',
    org_nr: '',
    email: '',
    phone: '',
    amount: '',
    message: '',
  }
  const [toSend, setToSend] = useState(initialState);

  const onSubmit = (e) => {
    e.preventDefault();

    send(
      'service_uac2irr',
      'template_20av0fo',
      toSend,
      'user_6kQpvMyWQ8NmQT1yJzlRZ'
    )
      .then((response) => {
        show({ 
          text: 'Ditt meddelande har skickats, vi återkommer så snabbt vi kan.',
          pos: 'top-center', 
          backgroundColor: '#4BB543',
          notifyIcon: 'email'
        })
        console.log('SUCCESS!', response.status, response.text);
        setToSend({ ...initialState });
      })
      .catch((err) => {
        show({ 
          text: 'Ditt meddelande kunde inte skickas, kontakta någon i teamet direkt för hjälp med ditt ärende.',
          pos: 'top-center', 
          backgroundColor: '#F44336',
          notifyIcon: 'email'
        })
        console.log('FAILED...', err);
        setToSend({ ...initialState });
      });
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  return (
    <div id="contact" className={classes.section}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={4}>
            <Card>
              <form className={classes.form} onSubmit={onSubmit}>
                <CardHeader color="primary" className={classes.cardHeader}>
                  <h4>Kontakta oss</h4>
                </CardHeader>
                <CardBody>
                  <CustomInput
                    labelText="Namn"
                    id="name"
                    name="from_name"
                    value={toSend.from_name}
                    handleChange={e => handleChange(e)}
                    formControlProps={{
                      fullWidth: true, 
                    }}
                    inputProps={{
                      type: "text",
                      endAdornment: (
                        <InputAdornment position="end">
                          <Person className={classes.inputIconsColor} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <CustomInput
                    labelText="Företagsnamn"
                    id="companyName"
                    name="company_name"
                    value={toSend.company_name}
                    handleChange={e => handleChange(e)}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      endAdornment: (
                        <InputAdornment position="end">
                          <People className={classes.inputIconsColor} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <CustomInput
                    labelText="Org Nr"
                    id="orgNr"
                    name="org_nr"
                    value={toSend.org_nr}
                    handleChange={e => handleChange(e)}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      endAdornment: (
                        <InputAdornment position="end">
                          <Dialpad className={classes.inputIconsColor} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <CustomInput
                    labelText="Email"
                    id="email"
                    name="email"
                    value={toSend.email}
                    handleChange={e => handleChange(e)}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "email",
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputIconsColor} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <CustomInput
                    labelText="Telefon"
                    id="phone"
                    name="phone"
                    value={toSend.phone}
                    handleChange={e => handleChange(e)}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      endAdornment: (
                        <InputAdornment position="end">
                          <Phone className={classes.inputIconsColor} />
                        </InputAdornment>
                      ),
                      autoComplete: "off",
                    }}
                  />
                  <CustomInput
                    labelText="Lånebelopp"
                    id="amount"
                    name="amount"
                    value={toSend.amount}
                    handleChange={e => handleChange(e)}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "text",
                      endAdornment: (
                        <InputAdornment position="end">
                          <Payment className={classes.inputIconsColor} />
                        </InputAdornment>
                      ),
                      autoComplete: "off",
                    }}
                  />
                  <CustomInput
                    labelText="Meddelande"
                    id="message"
                    name="message"
                    value={toSend.message}
                    handleChange={e => handleChange(e)}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "textField",
                      endAdornment: (
                        <InputAdornment position="end">
                          <TextFields className={classes.inputIconsColor} />
                        </InputAdornment>
                      ),
                      autoComplete: "off",
                    }}
                  />
                </CardBody>
                <CardFooter className={classes.cardFooter}>
                  <Button type="submit" simple color="primary" size="lg">
                    Skicka
                  </Button>
                </CardFooter>
              </form>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
