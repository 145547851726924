/*eslint-disable*/
import React from "react";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Hidden from "@material-ui/core/Hidden";
import ListItem from "@material-ui/core/ListItem";

// @material-ui/icons
import { Business, Contacts, People, MonetizationOn } from "@material-ui/icons";

// core components
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

function scrollToTargetAdjusted(target) {
  var element = document.getElementById(target);
  var headerOffset = 135;
  const bodyRect = document.body.getBoundingClientRect().top;
  const elementRect = element.getBoundingClientRect().top;
  const elementPosition = elementRect - bodyRect;
  const offsetPosition = elementPosition - headerOffset;
  
  setTimeout(window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  }), 500);
}

export default function HeaderLinks(props) {
  const classes = useStyles();

  return (
    <List className={classes.list}>
      <Hidden mdUp>
        <ListItem className={classes.listItem}>
          <Button
            onClick={() => scrollToTargetAdjusted('vision')}
            color="transparent"
            className={classes.navLink}
          >
            <Business className={classes.icons} /> Projektfinans
          </Button>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Button
            onClick={() => scrollToTargetAdjusted('team')}
            color="transparent"
            className={classes.navLink}
          >
            <People className={classes.icons} /> Teamet
          </Button>
        </ListItem>
      </Hidden>
      <Hidden smDown>
        <ListItem className={classes.listItem}>
          <CustomDropdown
            buttonText="Projektfinans"
            buttonProps={{
              className: classes.navLink,
              color: "transparent",
            }}
            dropdownList={[
              { name: "Vision", href: 'vision' },
              { name: "Teamet", href: 'team' },
            ]}
          />
        </ListItem>
      </Hidden>
      <ListItem className={classes.listItem}>
        <Button
          onClick={() => scrollToTargetAdjusted('finance')}
          color="transparent"
          className={classes.navLink}
        >
          <MonetizationOn className={classes.icons} /> Finansiering
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          onClick={() => scrollToTargetAdjusted('contact')}
          color="transparent"
          className={classes.navLink}
        >
          <Contacts className={classes.icons} /> Kontakt
        </Button>
      </ListItem>
    </List>
  );
}
