const cardFooterStyle = (theme) => ({
  cardFooter: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "transparent",
    padding: "0.9375rem 1.875rem",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
});

export default cardFooterStyle;
