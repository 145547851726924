import { title, container } from "assets/jss/material-kit-react.js";

const productStyle = (theme) => ({
  container,
  section: {
    padding: "70px 0",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 0",
      paddingBottom: 30,
    },
  },
  list: {
    "& li": {
      paddingLeft: 0,
      fontWeight: "bold",
      color: "#999",
    },
  },
  bgColor: {
    background: "#faedcd",
  },
  title: {
    ...title,
    marginBottom: "2rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: 26,
      wordWrap: "break-word",
    },
  },
  marginAuto: {
    marginLeft: "auto !important",
    marginRight: "auto !important",
    "& > div": {
      margin: 0,
    },
  },
  description: {
    color: "#999",
    textAlign: "left",
  },
});

export default productStyle;
