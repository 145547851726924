/* eslint-disable prettier/prettier */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// images
// import Carousel from "react-slick";
// import image1 from "assets/img/construction.jpg";
// import Card from "components/Card/Card.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import styles from "assets/jss/material-kit-react/views/componentsSections/aboutUsStyle.js";

const useStyles = makeStyles(styles);

export default function Vision() {
    const classes = useStyles();
    
    return (
        <div id="vision" className={classes.container}>
            <div className={classes.section}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={8}>
                        <h2 className={classes.title}>Projektfinans</h2>
                        <h5 className={classes.description}>
                        Projektfinans fokus är att hjälpa våra kunder att maximera deras finansiella möjligheter genom företagslån. 
                        Detta möjliggör vi genom många års erfarenhet, där vi arbetat inom både storbank och andra finansiella bolag. 
                        Vårt mål är att skräddarsy en finansieringslösning som matchar våra kunders behov för att hjälpa dom att växa. 
                        </h5>
                        <h5 className={classes.description}>
                            Vi ser till att arbeta nära våra kunder för att vi tillsammans ska kunna hitta kreativa lösningar.
                        </h5>
                    </GridItem>
                </GridContainer>
            </div>
        </div>
    );
}
